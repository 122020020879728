import React from 'react'
import Layout from "../components/Layout"

export default function NotFound() {
    return (
        <Layout>
        <div>
                <h2>404</h2>
                <h3>Sorry, that page does not exist.</h3>
            </div>
        </Layout>
    )
}

import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import {header, btn} from '../styles/home.module.css'

export default function Home({ data }) {
  return (
    <Layout>
    <section className={header}>
    <div>
      <h2>Inspired Media and Products</h2>
      <h3>That Move YOU to VIBRANT</h3>
      <p>Under Development - We are busy building great new content for this site!  Come back soon.</p>
      <Link className={btn} to="/projects">View Our Projects</Link>
    </div>
    </section>
  </Layout>
    )
  
}


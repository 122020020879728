import { Link } from 'gatsby'
import React from 'react'
import Layout from "../../components/Layout"
import *as styles from '../../styles/projects.module.css'

export default function Projects() {
    return (
        <Layout>
            <div className={styles.portfolio}>
                <h2>Portfiolo</h2>
                <h3>Projects & Media Available Now</h3>
                <p>Click on the Images below to get more details about each project.</p>
            </div>
            <div className={styles.projects}>
                <Link to={"/mobileApp"}>
                    <img src="/AppInHand-240.jpg" alt="Phone" width="240" height="240"/>
                    <h3>Quantum QRA App</h3>
                    <p>Mobile Application</p>
                </Link>
                <Link to={"/projects"}>
                    <img src="/FOL-240.jpg" alt="FOL " width="240" height="240"/>
                    <h3>Frequencies of Life</h3>
                    <p>Online Training Class</p>
                </Link>
                <Link to={"/projects"}>
                    <img src="/VNB-240.jpg" alt="Vibrant Now" width="240" height="240"/>
                    <h3>VibrantNow.be</h3>
                    <p>Online StoryStream</p>
                </Link>
            </div>
        </Layout>
    )
}

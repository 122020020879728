import React from "react";
import Layout from "../components/Layout";

export default function About() {
    return (
        <Layout>
            <div>
                <h1> About Us</h1>
                <p>Welcome to 2Vibrant! 2Vibrant creates Products and Projects based upon the simple notion that YOU can Become More Vibrant. 
                    Founded in 2017 by Gregory Becker, the company specializes in uniques products and educational media that
                    are purposfully designed to assist you in becoming more Vibrant. Check out our projects page to learn more. </p>
            </div>
        </Layout>
    )

}
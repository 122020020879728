import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function Navbar() {
    const data = useStaticQuery(graphql`
    query SiteInfo {
        site {
          siteMetadata {
            title
          }
        }
      }  
    `)

    const { title } = data.site.siteMetadata
    
    return (
        <nav>
                <img src="/LogoDesign-900 2.png" alt="Logo" width="540" height="180"/>
                <div className="links">       
                <Link to="/">Home</Link>
                <Link to="/projects">Projects</Link>
                <Link to="/about">About</Link>
                </div>
        </nav>
    )
}

import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import {header, btn} from '../styles/home.module.css'

export default function Home({ data }) {
  return (
    <Layout>
    <section className={header}>
    <div>
      <h2>Introducing</h2>
      <h3>The Quantum QRA App</h3>
      <p>The world's first mobile application for delivering Quantum Frequencies and Information Patterns specifically desgined for harmonizing 
        the Morphic Frequencies found in all natural living systems. Morphic Frequencies can be disrupted by enviromental conditions found in today's 
        world. </p>
        <p> Fortunately the use of the Quantum QRA App can stimulate the natural restoration of Morphic Frequencies and Fields of substances, helping these fields regain their natural state.</p>
    </div>
    <img src="/PhoneCutout-900.png" alt="Girl in a jacket" width="540" height="540"/>
    </section>
  </Layout>
    )
  
}